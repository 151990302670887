import { Select, Slider } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LangContext } from '../../contexts/langContext';
import { StateContext } from '../../contexts/stateContext';
import { defaultDob } from '../utilsDesign';
import { Icon } from './Icon';
import TimeTicks from './TimeTicks';

const { Option } = Select;

const MAX_HOURS = 11;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    font-weight: bold !important;
  }

  .ant-select-selection-item {
    font-weight: bold !important;
  }

  .ant-select-item-option-content {
    font-weight: bold !important;
  }
`;

export function TimeRange(props) {

  const {
    hideHandler,
    avatar = {},
    applyNewDate = () => void (0),
    style = {},
    top,
  } = props;
  const context = { ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;


  const [value, setValue] = useState(50);
  const [dob, setDob] = useState(avatar.dob ? avatar.dob : defaultDob);
  const [avatarId, setAvatarId] = useState(avatar.id);

  useEffect(() => {
    if (avatar.dob && avatar.dob !== dob) setDob(avatar.dob)
    if (!avatar.dob) setDob('1990-01-01T12:00')
    setValue(50)
    // if (avatarId.)
  }, [avatar])

  const saveNewDesign = () => {
    const ranges = getRanges();
    const totalMinutes = hoursPlus * 120; // Total minutes for the range
    const minutesOffset = ((value - 50) / 50) * (hoursPlus * 60);
    const selectedTime = new Date(ranges.originalDate);
    selectedTime.setMinutes(selectedTime.getMinutes() + minutesOffset);

    const formattedSelectedTime =
      selectedTime.getFullYear() +
      '-' +
      ('0' + (selectedTime.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + selectedTime.getDate()).slice(-2) +
      'T' +
      ('0' + selectedTime.getHours()).slice(-2) +
      ':' +
      ('0' + selectedTime.getMinutes()).slice(-2) +
      ':' +
      ('0' + selectedTime.getSeconds()).slice(-2);

    applyNewDate(formattedSelectedTime);
    setDob(formattedSelectedTime);
    setValue(50);
    hideHandler();
  };


  const applyNewDesign = (ranges, newValue) => {
    const selectedTime = new Date(ranges.originalDate);
    const totalMinutes = hoursPlus * 120; // Total minutes for the range
    const minutesOffset = ((newValue - 50) / 50) * (hoursPlus * 60);
    selectedTime.setMinutes(selectedTime.getMinutes() + minutesOffset);

    const formattedSelectedTime =
      selectedTime.getFullYear() +
      '-' +
      ('0' + (selectedTime.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + selectedTime.getDate()).slice(-2) +
      'T' +
      ('0' + selectedTime.getHours()).slice(-2) +
      ':' +
      ('0' + selectedTime.getMinutes()).slice(-2) +
      ':' +
      ('0' + selectedTime.getSeconds()).slice(-2);

    context.setState.setTryDesignDate(formattedSelectedTime);
  };

  const getRanges = () => {
    const originalDate = new Date(dob);
    const minDate = new Date(originalDate);
    minDate.setHours(originalDate.getHours() - hoursPlus);

    const maxDate = new Date(originalDate);
    maxDate.setHours(originalDate.getHours() + hoursPlus);

    return {
      min: minDate.toISOString(),
      time: originalDate.toISOString(),
      max: maxDate.toISOString(),
      originalDate,
    };
  };

  const getMarks = (value, ranges) => {
    const formatTime = (date) => {
      const pad = (num) => String(num).padStart(2, '0');
      const hours = pad(date.getHours());
      const minutes = pad(date.getMinutes());
      return `${hours}:${minutes}`;
    };

    const minTime = new Date(ranges.min);
    const maxTime = new Date(ranges.max);
    const originalTime = new Date(ranges.originalDate);

    const marks = {
      0: formatTime(minTime),
      50: formatTime(originalTime),
      100: formatTime(maxTime),
    };

    if (value !== 50) {
      const minutesOffset = ((value - 50) / 50) * (hoursPlus * 60);
      const selectedTime = new Date(originalTime);
      selectedTime.setMinutes(selectedTime.getMinutes() + minutesOffset);

      marks[value] = {
        style: {
          background: 'white',
          color: '#000000',
          height: '1.6em',
          padding: '0.8em 0 0 0',
          position: 'relative',
          zIndex: 1,
        },
        label: <strong>{formatTime(selectedTime)}</strong>,
      };
    }

    return marks;
  };

  const onChange = (newValue) => {
    setVal(newValue)
  };

  const setVal = (newValue) => {
    setValue(newValue);
    const ranges = getRanges();
    applyNewDesign(ranges, newValue); // Pass the newValue directly to applyNewDesign
  }

  const onChangeComplete = (newValue) => {
    // const ranges = getRanges();
    // applyNewDesign(ranges, newValue);
  };

  const [hoursPlus, setHoursPlus] = useState(1)

  const tooltipFormatter = (value) => {
    const ranges = getRanges();
    const totalMinutes = hoursPlus * 120; // Total minutes for the range
    const minutesOffset = ((value - 50) / 50) * (hoursPlus * 60);
    const selectedTime = new Date(ranges.originalDate);
    selectedTime.setMinutes(selectedTime.getMinutes() + minutesOffset);

    const pad = (num) => String(num).padStart(2, '0');
    const hours = pad(selectedTime.getHours());
    const minutes = pad(selectedTime.getMinutes());
    return `${hours}:${minutes}`;
  };

  const ranges = getRanges();
  const [marks, setMarks] = useState(getMarks(value, ranges));

  useEffect(() => {
    const ranges = getRanges();
    setMarks(getMarks(value, ranges))
  }, [hoursPlus])

  useEffect(() => {
    const ranges = getRanges();
    setMarks(getMarks(value, ranges));
  }, [value, dob]);

  const handleHoursChange = (value) => {
    setHoursPlus(value);
  };

  const hourOptions = Array.from({ length: MAX_HOURS }, (_, i) => i + 1);

  return (<>
    <div className="x_time_uncertain_range0" style={{ ...style }}>



      <div className="ui_radio0">
        <StyledSelect
          defaultValue={1}
          size='small'
          style={{ width: 86 }}
          onChange={handleHoursChange}
          value={hoursPlus}
        >
          {hourOptions.map(hours => (
            <Option key={hours} value={hours}>
              ±{hours} {$ui('time_hrs')}
            </Option>
          ))}
        </StyledSelect>
      </div>

      <div className={`mobile_v_yes x_button x_button--sm  x_button--ghost`}
        onClick={() => {
          setVal(50)
          hideHandler()
        }}
      >
        <Icon size={40}>close</Icon>
      </div>

      <div className="mobile_v_no x_time_uncertain_range_scale ">

        <TimeTicks hoursPlus={hoursPlus} dob={dob} />

        <StyledSlider
          onMouseEnter={(e) => e.stopPropagation()}
          // min={0}
          // max={100}
          dotSize={16}
          marks={marks}
          value={value}
          onChange={onChange}
          onChangeComplete={onChangeComplete} // Changed from onChangeComplete to onAfterChange
          // tooltip={{ formatter: tooltipFormatter }}
          tooltip={{ formatter: null }}
          dotActiveBorderColorActive='002bff'
        />
      </div>
      <div className="mobile_v_yes x_time_uncertain_range_scale ">
        <StyledSlider
          // min={0}
          // max={100}
          dotSize={16}
          // marks={marks}
          value={value}
          onChange={onChange}
          onChangeComplete={onChangeComplete} // Changed from onChangeComplete to onAfterChange
          tooltip={{
            formatter: tooltipFormatter
          }}
          // tooltip={{ formatter: null }}
          dotActiveBorderColorActive='002bff'
        />
      </div>

      <div className={`mobile_v_no x_button x_button--sm x_button--ghost ${value === 50 ? 'inactive' : ''}`} onClick={() => setVal(50)}>
        {$ui('time_reset')}
      </div>
      {!top && avatar.my && <>
        <div className={`mobile_v_no x_button x_button--sm  ${value === 50 ? 'x_button--ghost inactive' : ''}`} onClick={() => saveNewDesign()}>
        {$ui('time_save')}
      </div>
        <div className={`mobile_v_yes x_button x_button--sm  ${value === 50 ? 'x_button--ghost inactive' : ''}`} onClick={() => saveNewDesign()}>
          <Icon size={40}>check</Icon>
        </div>
      </>}
    </div>

  </>
  );
}



const StyledSlider = styled(Slider)`
  .ant-slider-track-active {
    background-color: ${({ controlSize }) => controlSize || '#002bff'}; /* Default green */
  }

  .ant-slider-handle {
    border-color: ${({ controlSize }) => controlSize || '#002bff'}; /* Default blue */
    background-color: #fff;
    z-index:2;
    transform: translateX(-50%) scale(1.6) !important;
  }
  .ant-slider-handle-active {
    border-color: ${({ controlSize }) => controlSize || '#002bff'}; /* Default blue */
    background-color: #fff;
    z-index:2;
  }
  .ant-slider-handle-active::after {
    box-shadow: 0 0 0 2px #002bff;
  }

  .ant-slider-dot-active {
    border-color: ${({ dotActiveBorderColor }) => dotActiveBorderColor || '#002bff'};
  }
  @media screen and (max-width: 479px) {
    .ant-slider-handle {
      transform: scale(2) !important;
    }
  }

`;
