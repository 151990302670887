import React, { useContext, useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import {
  convertGMTtoDateTime,
  defaultDate,
  defaultOffset,
  defaultTime,
  getMonth
} from '../utilsDesign';
import { City } from './City';
import { Time } from './Time';
import { TimeRange } from './TimeRange';



export function CityDateTime(props) {

  const {
    avatarToken,
    avatar = {},
    avatarDate,
    avatarTime,
    avatarCityId,
    avatarOffset,
  // setShowForm = true
  } = { ...props }

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };




  const onOk = (value) => {
    console.log('onOk: ', value);
  };

  const dateFormatList = [
    'MMM DD',
    'MMMM DD',
    'DD MMM',
    'MMMM DD',
    'DD/MM',
    'DD/MM',
    'DD-MM',
    'DD-MM'
  ];



  const yearFormatList = [
    'YYYY',
  ];


  const format = 'HH:mm';
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [offset, setOffset] = useState();
  const [cityId, setCityId] = useState();
  const [focused, setFocused] = useState();


  useEffect(() => {
    if (avatar && avatar.dob && avatar.aggr && avatar.aggr.city) {
      setDate(convertGMTtoDateTime(avatar.dob, 0, 'date'));
      setTime(convertGMTtoDateTime(avatar.dob, 0, 'time'));
      setOffset(avatar.aggr.city.offset);
      setCityId(avatar.aggr.city.id);
    } else {
      setDate(avatarDate ? avatarDate : defaultDate);
      setTime(avatarTime ? avatarTime : defaultTime);
      setOffset(avatarOffset ? avatarOffset : defaultOffset);
      setCityId(avatarCityId ? avatarCityId : null);
    }
  }, [avatar])


  // const [city, setCity] = useState({ value: '1', label: '1' })
  // const [city, setCity] = useState({})

  const [showFormInner, setShowFormInner] = useState(context.state.calcShown)


  const [changed, setChanged] = useState(false);
  const [submittable, setSubmittable] = useState(false);

  const [uncertain, setUncertain] = useState(false);





  useEffect(() => {
    if (!context.state.peoplePanel && avatar.design.id === 'dummy' && avatar.my) {
      context.setState.setCalcShown(true)
    } else {
      context.setState.setCalcShown(false)
      context.setState.setCitySearchActive(false)
    }
  }, [context.state.peoplePanel, avatar])

  useEffect(() => {
    if (!avatar.city_id || cityId !== avatar.city_id || date !== convertGMTtoDateTime(avatar.dob, 0, 'date') || time !== convertGMTtoDateTime(avatar.dob, 0, 'time')) {
      setChanged(true)
    } else {
      setChanged(false)
    }
    if (cityId) {
      setSubmittable(true)
    } else {
      setSubmittable(false)
    }
  }, [cityId, date, time])


  // useEffect(() => {
  //   setCityId(avatarCityId ? avatarCityId : null)
  //   setDate(avatarDate ? avatarDate : defaultDate)
  //   setTime(avatarTime ? avatarTime : defaultTime)
  //   if (!avatar.design || avatar.design.id === 'dummy' && avatar.my && !uncertain) {
  //     context.setState.setCalcShown(true)
  //   } else {
  //     if (avatar.design.id !== 'dummy' && !uncertain) context.setState.setCalcShown(false)
  //   }
  // }, [avatar]);

  useEffect(() => {
    setShowFormInner(context.state.calcShown);
  }, [context.state.calcShown]);

  useEffect(() => {
    setDate(avatarDate ? avatarDate : defaultDate);
    console.log('avatarDate = ', avatarDate);
  }, [avatarDate]);

  useEffect(() => {
    setTime(avatarTime ? avatarTime : defaultTime);
  }, [avatarTime]);

  useEffect(() => {
    if (avatarOffset && avatarOffset !== offset) {
      setOffset(avatarOffset)
    }
  }, [avatarOffset]);

  useEffect(() => {
    // console.log('avatarOffset = ', offset);
  }, [avatarOffset]);
  useEffect(() => {
    // console.log('offset = ', offset);
  }, [offset]);




  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }



  const handleNewDate = (dontHideCalc) => {
    if (!uncertain && dontHideCalc) {
      setShowFormInner(false)
      context.setState.setCalcShown(false)
      context.setState.setCitySearchActive(false)
    }

    // const { iso } = convertGMTtoDateTime(`${date}T${time}`, 0)

    const newData = {
      // ...avatar,
      token: avatar.token,
      // dob: adjustUTCByOffset(convertDateTimeToGMT(date, time, 0), offset),
      // dob: iso,
      dob: `${date}T${time}:00`,
      city_id: cityId,
    }
    console.log('newData = ', newData);
    context.x.updateAvatar(newData)
  }


  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the component
      if (!event.target.closest(".x0_search") && !event.target.closest(".__calcshown")) {
        // context.setState.setCalcShown(false)
        // avatar.design.id !== 'dummy' && context.setState.setCalcShown(false)
      }

    };

    document.addEventListener("click", handleClickOutside);
    // document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      // document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);



  const applyNewDate = (dateString) => {
    // const date = new Date(dateString)
    const processedDate = (prevDate) => {
      const year = String(prevDate.split('-')[0]);
      const month = String(prevDate.split('-')[1]).padStart(2, '0');
      const day = prevDate.split('-')[2].split('T')[0];
      return `${year}-${month}-${day}`;
    }
    const processedTime = (prevDate) => {
      const hours = String(prevDate.split('T')[1]).split(':')[0]
      const minutes = String(prevDate.split('T')[1]).split(':')[1]
      return `${hours}:${minutes}`;
    }
    const newDate = processedDate(dateString)
    const newTime = processedTime(dateString)
    console.log('processedDate = ', newDate);
    console.log('processedTime(dateString) = ', newTime);
    setDate(newDate)
    setTime(newTime)

    const newData = {
      token: avatar.token,
      dob: `${newDate}T${newTime}:00`,
      city_id: cityId,
    }
    console.log('newData = ', newData);
    context.x.updateAvatar(newData)
    setUncertain(false)

  }

  const monthRender = (current, info) => {
    return <div className="_f100 _fgap0 _tl _rel _fm"
      style={{ height: '2.4em' }}
    >
      <div className="x_time_hover">
      </div>
      <span style={{ paddingLeft: '0.4em', width: '2em', flexShrink: 0 }}>{current.$M + 1}</span> <b className='_tl' style={{ width: '10em' }}>{getMonth(current.$M, context.language)}</b>
    </div>
  }

  const monthCellRender = (current, info) => {
    if (info.type !== 'date') {
      // return `${current.$M + 1} ${info.originNode}`;
      return <div className="_f100 _fgap0 _tl _rel _fm"
        style={{ height: '2.4em' }}
      >
        <div className="x_time_hover">
        </div>
        <span style={{ paddingLeft: '0.4em', width: '2em', flexShrink: 0 }}>{current.$M + 1}</span> <b className='_tl' style={{ width: '10em' }}>{getMonth(current.$M, context.language)}</b>
      </div>

    }
    if (typeof current === 'number' || typeof current === 'string') {
      return <div className="ant-picker-cell-inner">{current}</div>;
    }

    const style = { width: '12em' }
    return (
      <div
        // className="x_time_drop_item ant-picker-cell-inner"
        className="x_time_drop_item"
        style={current.date() === 1 ? style : {}}>
        {current.date()}
      </div>
    );
  };


  const formatMonth = (value) => {
    return getMonth(value.month(), context.language)
  };



  return (
    <>

      <div
        onClick={(e) => {
          e.stopPropagation()
          avatar.design.id !== 'dummy' && context.setState.setCalcShown(false)
        }}
        className={showFormInner ? "x_dropdown_menu_z active" : "x_dropdown_menu_z inact"}
      />

      <div className={!showFormInner ? "x0_search_inner done" : `x0_search_inner ${context.state.citySearchActive ? 'focused-city' : ''}`}>
        <div className={!showFormInner ? `x0_omnifield0 done` : `x0_omnifield0 ${context.state.citySearchActive ? 'focused-city' : ''}`}>


          <div className={`x_calc_datetime ${focused === 'city' ? 'focused-city' : ''}  ${uncertain ? 'x_calc_datetime--un' : ''}`}
            onClick={() => setUncertain(false)}
          >
            {/* <City
                handleSelect={setCity}
              /> */}

            <City
              handleCityFocused={true}
              autoFocus={true}
              className='x_input--city'
              value={cityId}
              handleTimeOffset={setOffset}
              handleCityId={setCityId}
              avatar={avatar}
            />

            {/* {city && <CityDrop one={city} />} */}

            {/* <SelectCity /> */}

          </div>


          <div className={`x_calc_form_section ${context.state.citySearchActive ? 'focused-city' : ''}  ${uncertain ? 'x_calc_form_section--un' : ''}`}>
            <div
              className={`x_calc_datetime x_calc_datetime--datetime ${uncertain ? 'x_calc_datetime--un' : ''}`}
            >



              <div className="mobile_v_no"
                onClick={() => setUncertain(false)}
              >



                <DatePicker
                  picker="year"
                  suffixIcon={false}
                  variant={'borderless'}
                  changeOnBlur={true}
                  value={dayjs().year(date ? date.split('-')[0] : '1991')}
                  // value={dob}
                  format={yearFormatList}
                  // style={{ width: 64 }}
                  className={`x_input x_input--year ${uncertain ? 'x_input--un' : ''} __calcshown`}
                  onChange={(result) => {
                    // result = formatDate(result)
                    result && setDate((prevDate) => {
                      const year = result['$y'];
                      const month = String(prevDate.split('-')[1]).padStart(2, '0');
                      const day = String(prevDate.split('-')[2]).padStart(2, '0');
                      return `${year}-${month}-${day}`;
                    });
                  }}
                  // onOk={onOk}
                  // size="large"
                  showToday={false}
                  allowClear={false}
                  // bordered={false}
                  inputReadOnly={true}
                  onPressEnter={(e) => {
                    // e.preventDefault()
                    // // Handle the Enter key press here
                    // // Manually update the date state when Enter is pressed
                    // setDate((currentDate) => {
                    //   // currentDate = formatDate(currentDate)
                    //   if (dayjs(currentDate).isValid()) {
                    //     return currentDate;
                    //   }
                    //   // If the current date is not valid, you can provide a default value or handle it as needed
                    //   // return defacaultValue; // Replace 'defaultValue' with your default date
                    // });
                  }}
                // open={false} // Controlled open state
                // onOpenChange={handleOpenChange} // Handle open/close events
                />
              </div>


              <div className="mobile_v_yes"
                onClick={() => setUncertain(false)}
              >
                <DatePicker
                  picker="year"
                  placement={'topLeft'}
                  suffixIcon={false}
                  variant={'borderless'}
                  changeOnBlur={true}
                  value={dayjs().year(date ? date.split('-')[0] : '1991')}
                  // value={dob}
                  format={yearFormatList}
                  // style={{ width: 64 }}
                  className={`x_input x_input--year ${uncertain ? 'x_input--un' : ''} __calcshown`}
                  onChange={(result) => {
                    // result = formatDate(result)
                    result && setDate((prevDate) => {
                      const year = result['$y'];
                      const month = String(prevDate.split('-')[1]).padStart(2, '0');
                      const day = String(prevDate.split('-')[2]).padStart(2, '0');
                      return `${year}-${month}-${day}`;
                    });
                  }}
                  onOk={onOk}
                  // size="large"
                  showToday={false}
                  allowClear={false}
                  // bordered={false}
                  inputReadOnly={true}
                  onPressEnter={(e) => {
                    e.preventDefault()
                    // Handle the Enter key press here
                    // Manually update the date state when Enter is pressed
                    setDate((currentDate) => {
                      // currentDate = formatDate(currentDate)
                      if (dayjs(currentDate).isValid()) {
                        return currentDate;
                      }
                      // If the current date is not valid, you can provide a default value or handle it as needed
                      // return defacaultValue; // Replace 'defaultValue' with your default date
                    });
                  }}
                // open={false} // Controlled open state
                // onOpenChange={handleOpenChange} // Handle open/close events
                />
              </div>





              <div className="mobile_v_no"
                onClick={() => setUncertain(false)}
              >
                <DatePicker
                  picker="month"
                  cellRender={monthCellRender}
                  suffixIcon={false}
                  inputReadOnly={true}
                  popupClassName={'dateselector'}
                  className={`x_input x_input--month ${uncertain ? 'x_input--un' : ''} __calcshown`}
                  placement={'bottomLeft'}
                  // size={'large'}
                  // variant={'filled'}
                  variant={'borderless'}
                  changeOnBlur={true}
                  // defaultValue={dayjs(date, 'YYYY-MM-DD').format('DD/MMM/YYYY')}
                  value={dayjs(date)}
                  // value={dob}
                  // format={['MMMM']}
                  format={(value) => formatMonth(value)}

                  onChange={(result) => {
                    if (result) {
                      // Adjust for 0-indexed month and ensure two-digit formatting
                      const month = String(result['$M'] + 1).padStart(2, '0');
                      const day = String(result['$D']).padStart(2, '0');
                      // Use the original year from prevDate
                      setDate((prevDate) => `${prevDate.split('-')[0]}-${month}-${day}`);
                    }
                  }}
                  onOk={onOk}
                  // style={{ width: 80 }}
                  showToday={false}
                  allowClear={false}
                  // bordered={false}
                  onPressEnter={(e) => {
                    e.preventDefault()
                    // Handle the Enter key press here
                    // Manually update the date state when Enter is pressed
                    setDate((currentDate) => {
                      // currentDate = formatDate(currentDate)
                      if (dayjs(currentDate).isValid()) {
                        return currentDate;
                      }
                      // If the current date is not valid, you can provide a default value or handle it as needed
                      // return defacaultValue; // Replace 'defaultValue' with your default date
                    });
                  }}
                // onChange={onChange}
                />
              </div>
              <div className="mobile_v_yes"
                onClick={() => setUncertain(false)}
              >
                <DatePicker
                  picker="month"
                  cellRender={monthCellRender}
                  suffixIcon={false}
                  inputReadOnly={true}
                  popupClassName={'dateselector'}
                  className={`x_input x_input--month ${uncertain ? 'x_input--un' : ''} __calcshown`}
                  placement={'topLeft'}
                  // size={'large'}
                  // variant={'filled'}
                  variant={'borderless'}
                  changeOnBlur={true}
                  // defaultValue={dayjs(date, 'YYYY-MM-DD').format('DD/MMM/YYYY')}
                  value={dayjs(date)}
                  // value={dob}
                  // format={['MMMM']}
                  format={(value) => formatMonth(value)}

                  onChange={(result) => {
                    if (result) {
                      // Adjust for 0-indexed month and ensure two-digit formatting
                      const month = String(result['$M'] + 1).padStart(2, '0');
                      const day = String(result['$D']).padStart(2, '0');
                      // Use the original year from prevDate
                      setDate((prevDate) => `${prevDate.split('-')[0]}-${month}-${day}`);
                    }
                  }}
                  onOk={onOk}
                  // style={{ width: 80 }}
                  showToday={false}
                  allowClear={false}
                  // bordered={false}
                  onPressEnter={(e) => {
                    e.preventDefault()
                    // Handle the Enter key press here
                    // Manually update the date state when Enter is pressed
                    setDate((currentDate) => {
                      // currentDate = formatDate(currentDate)
                      if (dayjs(currentDate).isValid()) {
                        return currentDate;
                      }
                      // If the current date is not valid, you can provide a default value or handle it as needed
                      // return defacaultValue; // Replace 'defaultValue' with your default date
                    });
                  }}
                // onChange={onChange}
                />
              </div>



              <div className="mobile_v_no"
                onClick={() => setUncertain(false)}
              >
                <DatePicker
                  suffixIcon={false}
                  inputReadOnly={true}
                  popupClassName={'dateselector'}
                  className={`x_input x_input--day ${uncertain ? 'x_input--un' : ''} __calcshown`}
                  placement={'bottomLeft'}
                  // size={'large'}
                  // variant={'filled'}
                  variant={'borderless'}
                  changeOnBlur={true}
                  // defaultValue={dayjs(date, 'YYYY-MM-DD').format('DD/MMM/YYYY')}
                  value={dayjs(date)}
                  // value={dob}
                  // format={dateFormatList}
                  format={['DD']}

                  onChange={(result) => {
                    if (result) {
                      // Adjust for 0-indexed month and ensure two-digit formatting
                      const month = String(result['$M'] + 1).padStart(2, '0');
                      const day = String(result['$D']).padStart(2, '0');
                      // Use the original year from prevDate
                      setDate((prevDate) => `${prevDate.split('-')[0]}-${month}-${day}`);
                    }
                  }}
                  onOk={onOk}
                  // style={{ width: 52 }}
                  showToday={false}
                  allowClear={false}
                  // bordered={false}
                  onPressEnter={(e) => {
                    e.preventDefault()
                    // Handle the Enter key press here
                    // Manually update the date state when Enter is pressed
                    setDate((currentDate) => {
                      // currentDate = formatDate(currentDate)
                      if (dayjs(currentDate).isValid()) {
                        return currentDate;
                      }
                      // If the current date is not valid, you can provide a default value or handle it as needed
                      // return defacaultValue; // Replace 'defaultValue' with your default date
                    });
                  }}
                // open={false} // Controlled open state
                // onOpenChange={handleOpenChange} // Handle open/close events
                />
              </div>
              <div className="mobile_v_yes"
                onClick={() => setUncertain(false)}
              >
                <DatePicker
                  suffixIcon={false}
                  inputReadOnly={true}
                  popupClassName={'dateselector'}
                  className={`x_input x_input--day ${uncertain ? 'x_input--un' : ''} __calcshown`}
                  placement={'topLeft'}
                  // size={'large'}
                  // variant={'filled'}
                  variant={'borderless'}
                  changeOnBlur={true}
                  // defaultValue={dayjs(date, 'YYYY-MM-DD').format('DD/MMM/YYYY')}
                  value={dayjs(date)}
                  // value={dob}
                  // format={dateFormatList}
                  format={['DD']}

                  onChange={(result) => {
                    if (result) {
                      // Adjust for 0-indexed month and ensure two-digit formatting
                      const month = String(result['$M'] + 1).padStart(2, '0');
                      const day = String(result['$D']).padStart(2, '0');
                      // Use the original year from prevDate
                      setDate((prevDate) => `${prevDate.split('-')[0]}-${month}-${day}`);
                    }
                  }}
                  onOk={onOk}
                  // style={{ width: 52 }}
                  showToday={false}
                  allowClear={false}
                  // bordered={false}
                  onPressEnter={(e) => {
                    e.preventDefault()
                    // Handle the Enter key press here
                    // Manually update the date state when Enter is pressed
                    setDate((currentDate) => {
                      // currentDate = formatDate(currentDate)
                      if (dayjs(currentDate).isValid()) {
                        return currentDate;
                      }
                      // If the current date is not valid, you can provide a default value or handle it as needed
                      // return defacaultValue; // Replace 'defaultValue' with your default date
                    });
                  }}
                // open={false} // Controlled open state
                // onOpenChange={handleOpenChange} // Handle open/close events
                />
              </div>

              {/* <DatePicker defaultValue={defaultValue} onChange={onChange} /> */}

              {/* </Space> */}

              <div className="x_time00">
              <Time
                onClick={() => setUncertain(false)}
                onChange={(time) => {
                  // const formattedTime = `${time['$H'].toString().padStart(2, '0')}:${time['$m'].toString().padStart(2, '0')}`;
                  setTime(time);
                }}
                value={dayjs(`${time}`, format)}
                shown={!context.state.calcShown ? false : null}
              />

              {/* TODO: uncertain */}
                {/*
                {cityId && <div className={uncertain ? "x_time_uncertain active" : "x_time_uncertain"}
                  onClick={() => {
                    setUncertain(prev => !prev)
                    if (avatar.design.id === 'dummy') handleNewDate('dontHideCalc')
                  }}
                >
                  {uncertain && <div className="x_time_uncertain_clear"><div className="t_icon t_icon--40"><div>cancel</div></div></div>}
                  <div className="x_time_uncertain_ico">🤷‍♂️</div>
                </div>} */}

              </div>

              {/* <TimePicker
                popupClassName={'timeselector'}
                inputReadOnly={true}
                suffixIcon={false}
                changeOnBlur={true}
                variant={'borderless'}
                className='x_input x_input--time'
                // onOpenChange={handleOpenChangeTime} // Handle open/close events
                // defaultValue={dayjs('13:00', format)}
                value={dayjs(`${time}`, format)}
                format={format}
                // style={{ width: 64 }}
                // onChange={(event) => setTime(event.target.value)}
                onChange={(time) => {
                  const formattedTime = `${time['$H'].toString().padStart(2, '0')}:${time['$m'].toString().padStart(2, '0')}`;
                  setTime(formattedTime);
                }}
                onClose={(time) => {
                  const formattedTime = `${time['$H'].toString().padStart(2, '0')}:${time['$m'].toString().padStart(2, '0')}`;
                  setTime(formattedTime);
                }}
                onSelect={(time) => {
                  const formattedTime = `${time['$H'].toString().padStart(2, '0')}:${time['$m'].toString().padStart(2, '0')}`;
                  setTime(formattedTime);
                }}
                changeOnScroll={true}
                // size="large"
                showNow={false}
                allowClear={false}

                // bordered={false}
              /> */}



              {/* <div className="x_button_add active">
              <div>🤷‍♂️</div>
            </div> */}




            </div>


            {changed && submittable && <div className={`x_button x_button--submit `}
              onClick={handleNewDate}
            >
              <div className="t_icon t_icon--40">
                chevron_right
              </div>
            </div>}

            {changed && !submittable && <div className={`x_button x_button--calcsub inactive `}
              onClick={!avatar.design || avatar.design.id !== 'dummy' ? () => {
                setDate(avatarDate)
                setTime(avatarTime)
                setCityId(avatarCityId)
                context.setState.setCalcShown(false)
              } : () => void (0)}
            >
              <div className="t_icon t_icon--40">
                close
              </div>
            </div>}

            {!changed && avatar.design !== 'dummy' && <div className="x_button x_button--notchanged"
              onClick={(e) => {
                e.preventDefault()
                context.setState.setCalcShown(false)
              }}
            >
              <div className="t_icon t_icon--40">
                done
              </div>
            </div>}
          </div>

          {uncertain &&
            <TimeRange {...props} applyNewDate={applyNewDate}
              hideHandler={() => setUncertain(false)}
            />
          }

        </div>
      </div>



    </>
  )
}