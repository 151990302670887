import React, { useContext, useEffect, useRef, useState } from 'react';
import { LangContext } from '../../contexts/langContext';


export function Time({ value, onChange, shown, onClick }) {
  const context = { ...useContext(LangContext) };
  const [state, setState] = useState(shown);
  const [valueInner, setValueInner] = useState();
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);


  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the component
      if (!event.target.closest(".x_time0")) {
        setState(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    if (shown !== state) setState(shown)
  }, [shown])

  useEffect(() => {
    const h = hours.toString().padStart(2, '0')
    const m = minutes.toString().padStart(2, '0')
    if (formatTime(`${h}:${m}`) !== valueInner) {
      setValueInner(formatTime(`${h}:${m}`))
      onChange(formatTime(`${h}:${m}`))
    }
  }, [hours, minutes])

  useEffect(() => {
    setValueInner(`${value['$H'].toString().padStart(2, '0')}:${value['$m'].toString().padStart(2, '0')}`)
    if (value['$H'] !== hours) setHours(value['$H']);
    if (value['$m'] !== minutes) setMinutes(value['$m']);
  }, [value])

  const formatTime = (input) => {
    // if (input.length === 2) return input += ':'
    return input
  }

  const getAmPm = (hour) => {
    const suffix = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12; // Convert 0-23 hour format to 1-12 format
    return `${hour12} ${suffix}`;
  };

  const scrollHours = useRef()
  const scrollMinutes = useRef()

  // const formatTime = (input) => {
  //   // Remove non-numeric characters
  //   const numbersOnly = input.replace(/\D/g, '');

  //   // Early return if empty
  //   if (!numbersOnly) return '';

  //   // Extract hours and minutes
  //   let hours = numbersOnly.substring(0, 2);
  //   let minutes = numbersOnly.substring(2, 4);

  //   // Validate and correct hours and minutes
  //   hours = Math.min(23, parseInt(hours, 10) || 0).toString().padStart(2, '0');
  //   minutes = Math.min(59, parseInt(minutes, 10) || 0).toString().padStart(2, '0');

  //   return `${hours}:${minutes}`;
  // };

  // const handleChange = (e) => {
  //   const { value } = e.target;
  //   const formattedTime = formatTime(value);
  //   setValueInner(formattedTime);
  // };

  const timeItems = Array.from({ length: 60 }, (_, i) => i + 1);
  const hourItems = Array.from({ length: 24 }, (_, i) => i + 1);


  useEffect(() => {
    if (state) {
      if (scrollMinutes.current && scrollHours.current) {
        const activeMinutesItem = scrollMinutes.current.querySelector('.active');
        const activeHoursItem = scrollHours.current.querySelector('.active');

        if (activeMinutesItem) {
          activeMinutesItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }

        if (activeHoursItem) {
          activeHoursItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
    }
  }, [state]);


  return (
    <>
      <div className="x_time0" onClick={onClick}>

        {/* <input
          onClick={() => setState(prev => !prev)}
          className={state ? "x_input x_input--time x_input--time--active" : "x_input x_input--time"}
          value={valueInner}
          onChange={handleChange}
          placeholder="HH:MM"
        /> */}

        <div
          onClick={() => setState(prev => !prev)}
          className={state ? "x_input x_input--time x_input--time--active" : "x_input x_input--time"}
        >
          {Boolean(valueInner) ? valueInner : "HH:MM"}
          {/* {valueInner} */}
        </div>

        <div className={state ? "x_time_drop0 active" : "x_time_drop0"}>

          <div className="x_time_drop">
            <div className="x_time_drop_scroll0">
              <div className="x_time_drop_scroll" ref={scrollHours}>
                {hourItems.map((number) => (
                  <div key={number} className={hours === number - 1 ? "x_time_drop_item active" : "x_time_drop_item"}
                    onClick={() => setHours(number - 1)}
                  >
                    {context.language === 'en' && <div className="x_time_drop_num">
                      {getAmPm(number - 1)}
                    </div>}
                    <div className={`x_time_drop_num ${context.language === 'en' ? 'x_time_drop_num--2' : ''}`}>
                      {number - 1}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="x_time_drop_scroll0">
                <div className="x_time_drop_scroll">
                  <div className="x_time_drop_item">
                    <div className="x_time_drop_num">
                      <div>AM</div></div>
                  </div>
                  <div className="x_time_drop_item active">
                    <div className="x_time_drop_num"><div>PM</div></div>
                  </div>
                </div>
              </div> */}
            <div className="x_time_drop_scroll0">
              <div className="x_time_drop_scroll" ref={scrollMinutes}>
                {timeItems.map((number) => (
                  <div key={number} className={minutes === number - 1 ? "x_time_drop_item active" : "x_time_drop_item"}
                    onClick={() => {
                      setMinutes(number - 1)
                      setState(false)
                    }}
                  >
                    <div className="x_time_drop_num">
                      {number - 1}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>


      </div >
    </>
  )
}