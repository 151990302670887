import React, { useEffect, useState } from 'react';
import { Button } from '../UiKit/Button';
import { AdminMenu } from './AdminMenu';

const API_URL = process.env.REACT_APP_API_URL;
const APP_AIRTABLE_CACHE = process.env.REACT_APP_AIRTABLE_CACHE;

export function SyncLanguage() {
  const [changes, setChanges] = useState('');
  const [profilesData, setProfilesData] = useState('');
  const [typesData, setTypesData] = useState('');
  const [planetsData, setPlanetsData] = useState('');
  const [linesData, setLinesData] = useState('');
  const [gatesData, setGatesData] = useState('');
  const [channelsData, setChannelsData] = useState('');
  const [environmentsData, setEnvironmentsData] = useState('');
  const [authoritiesData, setAuthoritiesData] = useState('');
  const [centersData, setCentersData] = useState('');
  const [narrativesData, setNarrativesData] = useState('');
  const [narrativePagesData, setNarrativePagesData] = useState('');
  const [uiData, setUiData] = useState('');
  const [videosData, setVideosData] = useState('');
  const [celebsData, setCelebsData] = useState('');

  //TODO: Дима, решить проблему с путем
  const path = `${APP_AIRTABLE_CACHE}`;

  const fetchStaticData = async () => {
    try {
      const responses = await Promise.all([
        fetch(`${path}/changes.json`),
        fetch(`${path}/profiles.json`),
        fetch(`${path}/types.json`),
        fetch(`${path}/planets.json`),
        fetch(`${path}/lines.json`),
        fetch(`${path}/gates.json`),
        fetch(`${path}/channels.json`),
        fetch(`${path}/environments.json`),
        fetch(`${path}/authorities.json`),
        fetch(`${path}/centers.json`),
        fetch(`${path}/narratives.json`),
        fetch(`${path}/narrative_pages.json`),
        fetch(`${path}/ui.json`),
        fetch(`${path}/videos.json`),
        fetch(`${path}/celebs.json`),
      ]);

      const data = await Promise.all(responses.map(response => response.json()));

      setChanges(JSON.stringify(data[0], null, 2));
      setProfilesData(JSON.stringify(data[1], null, 2));
      setTypesData(JSON.stringify(data[2], null, 2));
      setPlanetsData(JSON.stringify(data[3], null, 2));
      setLinesData(JSON.stringify(data[4], null, 2));
      setGatesData(JSON.stringify(data[5], null, 2));
      setChannelsData(JSON.stringify(data[6], null, 2));
      setEnvironmentsData(JSON.stringify(data[7], null, 2));
      setAuthoritiesData(JSON.stringify(data[7], null, 2));
      setCentersData(JSON.stringify(data[8], null, 2));
      setNarrativesData(JSON.stringify(data[9], null, 2));
      setNarrativePagesData(JSON.stringify(data[10], null, 2));
      setUiData(JSON.stringify(data[11], null, 2));
      setVideosData(JSON.stringify(data[12], null, 2));
      setCelebsData(JSON.stringify(data[13], null, 2));
    } catch (error) {
      console.error('Error fetching static data:', error);
    }
  };


  const handleSync = async () => {
    try {
      const response = await fetch(`${API_URL}/admin/api/utils/load-air-tables`, {
        method: 'POST',
      });
      if (response.ok) {
        console.log('Sync successful');
      } else {
        console.error('Sync failed');
      }
    } catch (error) {
      console.error('Error during sync:', error);
    }
  };

  const handleSyncIndividual = async (table) => {
    try {
      const response = await fetch(`${API_URL}/admin/api/utils/load-air-tables/${table}`, {
        method: 'POST',
      });
      if (response.ok) {
        console.log('Sync successful');
      } else {
        console.error('Sync failed');
      }
    } catch (error) {
      console.error('Error during sync:', error);
    }
  };

  useEffect(() => {
    fetchStaticData();
  }, []);

  return (<>
    <AdminMenu active="texts" />
    <div className="b" style={{ position: 'relative', minHeight: '100vh', padding: '2em 2em 12em 2em', background: '#efefef' }}>
      <div className='_fv _fgap2'>
        <h1>💬 Language</h1>
        <div className="_f _fwrap _fgap2">
          <Button onClick={handleSync}>Sync</Button>
        </div>


        <div className="_f _fwrap _fgap2">
          <div>
            <h2>Changes</h2>
            <textarea value={changes} readOnly rows={10} cols={50} />
          </div>
          <div>
            <h2>Profiles</h2>
            <textarea value={profilesData} readOnly rows={10} cols={50} />
            <Button onClick={() => handleSyncIndividual('profiles')}>Sync</Button>
          </div>
          <div>
            <h2>Types</h2>
            <textarea value={typesData} readOnly rows={10} cols={50} />
            <Button onClick={() => handleSyncIndividual('types')}>Sync</Button>
          </div>
          <div>
            <h2>Planets</h2>
            <textarea value={planetsData} readOnly rows={10} cols={50} />
            <Button onClick={() => handleSyncIndividual('planets')}>Sync</Button>
          </div>
          <div>
            <h2>Lines</h2>
            <textarea value={linesData} readOnly rows={10} cols={50} />
            <Button onClick={() => handleSyncIndividual('lines')}>Sync</Button>
          </div>
          <div>
            <h2>Gates</h2>
            <textarea value={gatesData} readOnly rows={10} cols={50} />
            <Button onClick={() => handleSyncIndividual('gates')}>Sync</Button>
          </div>
          <div>
            <h2>Channels</h2>
            <textarea value={channelsData} readOnly rows={10} cols={50} />
            <Button onClick={() => handleSyncIndividual('channels')}>Sync</Button>
          </div>
          <div>
            <h2>Environments</h2>
            <textarea value={environmentsData} readOnly rows={10} cols={50} />
            <Button onClick={() => handleSyncIndividual('environments')}>Sync</Button>
          </div>
          <div>
            <h2>Authorities</h2>
            <textarea value={authoritiesData} readOnly rows={10} cols={50} />
            <Button onClick={() => handleSyncIndividual('authorities')}>Sync</Button>
          </div>
          <div>
            <h2>Centers</h2>
            <textarea value={centersData} readOnly rows={10} cols={50} />
            <Button onClick={() => handleSyncIndividual('centers')}>Sync</Button>
          </div>
          <div>
            <h2>Narratives</h2>
            <textarea value={narrativesData} readOnly rows={10} cols={50} />
            <Button onClick={() => handleSyncIndividual('narratives')}>Sync</Button>
          </div>
          <div>
            <h2>Narrative Pages</h2>
            <textarea value={narrativePagesData} readOnly rows={10} cols={50} />
            <Button onClick={() => handleSyncIndividual('narrative_pages')}>Sync</Button>
          </div>
          <div>
            <h2>UI</h2>
            <textarea value={uiData} readOnly rows={10} cols={50} />
            <Button onClick={() => handleSyncIndividual('ui')}>Sync</Button>
          </div>
          <div>
            <h2>Videos</h2>
            <textarea value={videosData} readOnly rows={10} cols={50} />
            <Button onClick={() => handleSyncIndividual('videos')}>Sync</Button>
          </div>
          <div>
            <h2>Celebs</h2>
            <textarea value={celebsData} readOnly rows={10} cols={50} />
            <Button onClick={() => handleSyncIndividual('celebs')}>Sync</Button>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}
