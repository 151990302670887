import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LangContext } from '../contexts/langContext';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { Avatar } from './UiKit/Avatar';
import { AvatarName } from './UiKit/AvatarName';
import { UserSocialLine } from './UiKit/AvatarPanel';
import { Background } from './UiKit/Background';
import { City } from './UiKit/City';
import { Icon } from './UiKit/Icon';
import { Preloader, Preloader0, PreloaderButton } from './UiKit/kit';
import { Preview } from './UiKit/Preview';
import { ProfileCircle } from './UiKit/ProfileCircle';
import { UserDropDown } from './UiKit/UserDropDown';


export function Persona(props) {

  const {
    width,
    height,
    noClick,
    active,
    customHandler,
    listId = 1,
    user,
    avatar,
    panelExpanded,
    actions = [],
    // isMe,
    fullList,
    followings = [],
    followers = [],
    refetch = () => void (0),
    addLine = 'city', //'followings'/'city'/'followers'/'none',
    noInfo = false,
    addClassName = 'people',
    hideName,
    celeb = false
  } = { ...props };


  const context = {
    ...useContext(SocialContext),
    ...useContext(StateContext),
    ...useContext(LangContext)
  };
  const $ui = context.$ui;
  const isMe = context.me ? avatar.token === context.me.avatar.token : false;

  // const design = context.x.getDesign(avatar);
  const design = avatar.design && avatar.design['id'] !== 'dummy' ? avatar.design : null;
  const navigate = useNavigate();


  const { useravatarId } = useParams();
  const bodyContainer = useRef(null);

  const [activeInner, setActive] = useState(active)


  const desC = design && design.des ? design.des.toLowerCase() : '';
  const prsC = design && design.prs ? design.prs.toLowerCase() : '';
  const typC = design && design.type ? design.type.toLowerCase() : '';


  useEffect(() => {
    if (!noClick && useravatarId && (user || avatar)) {
      let userToken = user ? user.token : null;
      let avatarToken = avatar ? avatar.token : null;
      setActive(useravatarId === userToken || useravatarId === avatarToken)
    }
  }, [useravatarId, user, avatar])



  const isFriend = () => {
    if (!user || !context.me) return true;
    const arrayFollowings = context.followings ? context.followings : [];
    let friendsTokens = arrayFollowings.length ? arrayFollowings.map(one => one.user.token) : []
    friendsTokens.push(context.me.user.token)
    if (friendsTokens.includes(user.token)) return true;
  }

  // const isCeleb = () => {
  //   console.log('user = ', user);
  //   if (!user) return false;
  //   const celebs = Object.keys(context.celebs);
  //   if (user && celebs.includes(user.token)) return true;
  // }
  const isCeleb = () => {
    return panelExpanded && celeb
  }




  return (
    <>
      <div className={fullList ? "x_choose_item x_choose_item--h" : `x_choose_item ${addClassName ? `x_choose_item--${addClassName}` : ''}`}>

        <div className={fullList ? "x_choose_one0 x_choose_one0--h" : `x_choose_one0 ${addClassName ? `x_choose_one0--${addClassName}` : ''}`} >


          {/* {user && celeb && <div className="x_choose_user_celeb"
            style={{
              backgroundImage: `url(${user.image})`
            }}
          />} */}
          {Boolean(user && user.token)
            && design
            && design['id']
            && design['id'] !== 'dummy'
            && isCeleb()
            && <div className="x_choose_user_celeb">
            <Avatar
              avatar={avatar}
              style={{
                fontSize: '4.2em'
              }}
            />
          </div>}


          {user && context.followingUser.includes(user.token)
            && <Preloader0 style={{
              paddingBottom: '4em', zIndex: 10, height: '100%', width: '100%',
              backgroundColor: 'rgba(255,255,255,0.8)'
            }}>
              <Preloader width={80} height={80} />
            </Preloader0>}

          {user && avatar && context.deletingAvatar.includes(avatar.token)
            && <Preloader0 style={{
              paddingBottom: '4em', zIndex: 10, height: '100%', width: '100%',
              backgroundColor: 'rgba(255,255,255,0.8)'
            }}>
              <Preloader width={80} height={80} />
              {$ui('status_deleting')}
            </Preloader0>}


          {isMe && <div className="x_choose_me" />}

          <div
            className={panelExpanded ? `x_choose_one x_choose_one--v` : `x_choose_one ${addClassName ? `x_choose_one--${addClassName}` : ''}`}
          // className={panelExpanded ? "x_choose_one x_choose_one--v" : "x_choose_one"}
          >




            {!noInfo
              && design
              && design['id']
              && design['id'] !== 'dummy'
              && <div className="x_choose_one_ava">
                <ProfileCircle
                  design={design}
                  profileShow={true}
                  typeShow={true}
                  type='sm' />
              </div>}

            {!noClick && <div className="x_choose_hover" />}

            {Boolean(actions.length)
              && <UserDropDown
                listId={listId}
                avatar={avatar}
                user={user}
                actions={actions}
              />}

            <Background
              design={design}
              className={`x_edu_aurabg--${addClassName}`}
            />

            <div
              // style={{ width: width ? width : 'inherit' }}
              className={`x_choose_bodygraph x_choose_bodygraph--${addClassName}`}
              // className={panelExpanded ? "x_choose_bodygraph x_choose_bodygraph--mypeople" : "x_choose_bodygraph"}
              onClick={() => {
                if (!noClick) {
                  if (customHandler) {
                    customHandler({
                      user: user,
                      avatar: avatar
                    })
                  } else {
                    context.setState.showPeoplePanel(false);
                    // navigate(`/u/${user ? user.token : 0}-${avatar.token}`)
                    navigate(`/u/${user ? user.token : avatar.token}`)
                  }
                }
              }
              }
            >
              <div className={`x_bodygraph x_bodygraph--${addClassName}`}
                style={{ width: width ? width : 'inherit' }}
              >
                <div className="x_bodygraph_base x_bodygraph_base--sm" ref={bodyContainer}>
                  {/* {socialContext.x.getDesign(dob)} */}
                  <Preview
                    arrowsB={true}
                    arrowsP={true}
                    // width={80}
                    // height={120}
                    aura={false}
                    design={design}
                    preloader={!design && avatar.dob}
                    parentContainer={bodyContainer}
                    addClassName={addClassName}
                  />
                  {/* <img src="https://assets-global.website-files.com/643f3bd2d71a69bb42b11dfb/643f3dfa49c20525e8af293b_bodygraph.svg" loading="lazy" /> */}
                </div>
              </div>

              {Boolean(typC) && <div className="x_choose_prsdes0">
                <div className={`x_type-xs ${desC === typC ? 'x_type-xs--same' : ''}`}><div className={`x_typexs ${desC}`} /></div>
                {/* <div className={`x_type-xs x_type-xs--same`}><div className={`x_typexs ${typC}`} /></div> */}
                <div className={`x_type-xs  ${prsC === typC ? 'x_type-xs--same' : ''}`}><div className={`x_typexs ${prsC}`} /></div>
              </div>}


            </div>






            {/* <div className="x_choose_title">

        </div> */}

          </div>


          {!noInfo && <div className="x_choose_user0">

            {user
              && !isFriend()
              // && !isCeleb()
              && !isCeleb()
              && <div
                onClick={() => {
                  if (!noClick) {
                    context.x.follow(user.token,
                      () => refetch(user.token)
                      // setFollowersQ(prev => prev + 1)
                    )
                  }
                }}
                className="x_choose_user_addfriend">
                <div className="t_icon t_icon--20">
                  <div>person_add</div>
                </div>
              </div>}



            <div className="x_choose_user_actionable">

              {!user && (!design || design.type === 'dummy') && <div className="x_choose_user_ava">

                {/* {design && avatar && Boolean(avatar.image) && <div className="x_choose_user_ava_image"
                  style={{ backgroundImage: avatar.image ? `url(${avatar.image})` : '' }}
                />} */}



                {/* {design && user && !Boolean(avatar.image) && <div className={`t_icon t_icon--ava ${getAvatarClass(design)}`}
                >no_accounts</div>} */}

                {/* {design && <Icon size={40}>qr_code</Icon>} */}

                {!design && <Icon size={40}>question_mark</Icon>}

              </div>}

              {design
                && avatar
                // && !isCeleb()
                // && !Boolean(user && user.token)
                && !isCeleb()
                && <Avatar
                  avatar={avatar}
                  type='lg'
                  size='lg'
                />}


              <div className={`x_choose_user_info ${
                // isCeleb() &&
                isCeleb() ? 'x_choose_user_info--celeb' : ''}`}>

                {avatar && <div className="x_choose_user_nick ellipsis">
                  {/* <div className="x_choose_user_badge" /> */}
                  {/* {user ? user.name : avatar.name} */}
                  <AvatarName avatar={avatar} hideName={hideName} />
                </div>}
                {user && addLine === 'followings' && <div className='x_choose_user_2nd'>
                  {user.aggr && user.aggr.followers_count && <div>{user.aggr.followings_count} {$ui('persona_friends_count')}</div>}
                </div>}
                {user && addLine === 'city' && <div className='x_choose_user_2nd'>
                  {user.aggr && user.aggr.city && <div>{user.aggr.city.city}</div>}
                </div>}

                {addLine !== 'none' && !user && design && design['id'] !== 'dummy' && <div>{$ui('persona_invite')}</div>}

                {!user && !design && <div>{$ui('persona_setup')}</div>}


              </div>



            </div>

            {!noClick
              && <div className={activeInner ? "x_hover active" : "x_hover"}
                onClick={() => {
                  if (!noClick) {
                    if (customHandler) {
                      customHandler({
                        user: user,
                        avatar: avatar
                      })
                    } else {
                      context.setState.showPeoplePanel(false);
                      navigate(`/u/${user ? user.token : avatar.token}`)
                    }
                  }
                }}
              />}

            <div className="x_mobile_tap"
              onClick={() => {
                if (!noClick) {
                  if (customHandler) {
                    customHandler({
                      user: user,
                      avatar: avatar
                    })
                  } else {
                    context.setState.showPeoplePanel(false);
                    navigate(`/u/${user ? user.token : avatar.token}`)
                  }
                }
              }}
            />

          </div>}


        </div >

        {fullList
          && user
          && <>

          <div className="x_choose_h-info">


            <div className="x_choose_user0 x_choose_user0--h"
              onClick={() => {
                if (!noClick) {
                  context.setState.showPeoplePanel(false);
                  // navigate(`/u/${user ? user.token : 0}-${avatar.token}`)
                  navigate(`/u/${user ? user.token : avatar.token}`)
                }
              }}
            >
              <div className="x_choose_h_user">




                <Avatar
                  avatar={avatar}
                  // user={user}
                  type='lg'
                  size='lg'
                // className='x_choose_user_ava_image x_choose_user_ava_image--lg'
                />


                <div className='x_choose_user_name'>
                  <AvatarName avatar={avatar} hideName={hideName} />
                </div>
              </div>

              <div className="x_choose_user_city">
                {Boolean(user.city_id) && <City
                  value={user.city_id}
                  noClick={true}
                  className='city_static'
                />}
              </div>

            </div>


            <div className="x_choose_user_actions">

              {context.me && <UserSocialLine
                {...props}
                vertical={true}
              />}

            </div>
          </div>

          </>}

      </div>
    </>
  )
}