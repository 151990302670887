import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { MessageContext } from '../../contexts/messageContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { Avatar } from '../UiKit/Avatar';
import { AvatarName } from '../UiKit/AvatarName';
import { Preview } from '../UiKit/Preview';
import { CHANNEL_TOKEN_LENGTH } from '../utilsDesign';
import { ChatMessageRead, ChatMessageTime } from './ChatMessageRead';

export const ChatsListItem = React.memo(({
  handleState,
  memberData = {},
  chatId,
  active,
  setShowChats = () => void (0)
}) => {
  const navigate = useNavigate();

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const messageContext = { ...useContext(MessageContext) }

  const [newCount, setNewCount] = useState(0)
  const [lastMessageTheir, setLastMessageTheir] = useState({})
  const [lastMessage, setLastMessage] = useState({})

  useEffect(() => {
    messageContext.getUnreadFor(chatId, (newQ) => {
      setNewCount(newQ)
    })
  }, [messageContext.unread])

  useEffect(() => {
    const chat = messageContext.cachedChats[chatId];
    if (chat && chat.length) {
      const lastMessage = chat[chat.length - 1];
      // lastMessage.read = true;
      setLastMessage(lastMessage)
      const chatTheir = chat.filter(one => one.from !== context.me.user.token);
      const lastMessageTheir = chatTheir[chatTheir.length - 1] ? chatTheir[chatTheir.length - 1] : {}
      setLastMessageTheir(lastMessageTheir)
    }
  }, [messageContext.cachedChats, newCount])

  return (
    <div
      className={active ? "x_chats_li active" : "x_chats_li"} >


      <div className="x_hover"
        onClick={() => {
          // navigate(`/chats/${chatId}`)
          handleState(chatId)
          setShowChats()
        }}
      />

      <div className="x_mobile_tap"
        onClick={() => {
          // navigate(`/chats/${chatId}`)
          handleState(chatId)
          setShowChats()
        }}
      />

      <div className="x_chats_name">

        <div className="x_choose_user_ava">
          <Avatar
            style={{
              fontSize: '1.2em'
            }}
            avatar={memberData.avatar}
          />
        </div>


        <div className="x_choose_user_info x_choose_user_info--chats">
          <div className="x_choose_user_1">
            <div className="x_choose_user_nick x_choose_user_nick--chats">
              {memberData.avatar && <AvatarName avatar={memberData.avatar} />}
            </div>
          </div>


          {Boolean(chatId) && chatId.length === CHANNEL_TOKEN_LENGTH &&
            <div className="x_choose_user_2">
              <div className={!Boolean(lastMessageTheir.read) ? "x_chats_theme unread" : "x_chats_theme"}>

            {Boolean(newCount) && !Boolean(lastMessageTheir.read)
              && <div className="x_chats_unread">
                <div className="x_noti0 x_noti0--chat" />
              </div>
            }

            {Boolean(lastMessage.text) && <div className='x_chats_theme_text'>
                  {/* <Txt> */}
                {lastMessage.text}
                  {/* </Txt> */}
                </div>}

              </div>
              <ChatMessageRead {...lastMessage}
                style={{ position: 'relative', bottom: 1 }}>
                {/* {lastMessage.timestamp} */}
                <ChatMessageTime {...lastMessage} />
              </ChatMessageRead>
            </div>}


        </div>
        {memberData.avatar.token !== 'hd_cafe' && <div className="x_chats_body">
          <div className="x_choose_one x_choose_one--chats">
            <div className="x_choose_bodygraph x_choose_bodygraph--chats">
              <div className="x_bodygraph x_bodygraph--chats">
                <div className="x_bodygraph_base">

                  {/* {!memberData.avatar && <Preloader />} */}

                  {memberData.avatar && <Preview
                    width={60}
                    height={70}
                    design={memberData.avatar.design}
                  />}
                </div>
              </div>
            </div>
            {/* <div className="x_edu_bg g">
              <div className="x_edu_aurabg active mg x_edu_aurabg--choose" />
            </div> */}
          </div>
        </div>}
      </div>
    </div>

  )
})

