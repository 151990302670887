import { Col, Input, List, message, Row, Segmented } from 'antd';
import React, { useEffect, useState } from 'react';
import { getMessageDate, getMessageTime } from '../Chats/ChatMessageRead';
import { Button } from '../UiKit/Button';
import { Switch } from '../UiKit/Switch';
import { _previewDigest, _publishDigest } from '../utilsAdmin';
import { USER_TOKEN_LENGTH } from '../utilsDesign';
import { EmailPreview } from './EmailPreview';
import { AdminMenu } from './AdminMenu';

const { TextArea } = Input;

const API_URL = process.env.REACT_APP_API_URL;

export const Skynet = () => {
  const [currentSet, setCurrentSet] = useState('drafts');
  const [digest, setDigest] = useState('');
  const [recepients, setRecepients] = useState();

  const [digestList, setDigestList] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [allowed, setAllowed] = useState(false);
  useEffect(() => {
    setAllowed(false)
  }, [editingId])


  const setRecepientsArrray = (a) => {
    if (a.length && a.split(',').length > 0) {
      let r = a
        .replace('\n', null)
        .split(',')
        .filter(one => one && one.length === USER_TOKEN_LENGTH);
      console.log('r = ', r);
      setRecepients(r);
    }
  }

  const getCurrentDigestText = () => {
    let t = digestList.filter(o => o._id === editingId)
    console.log('t = ', t);
    return t[0] ? t[0].digest : null;
  }
  const filterList = (arr, currentSet) => {
    if (!currentSet) { return arr } else {
      if (currentSet === 'drafts') {
        return arr.filter(one => !one.published)
      }
      if (currentSet === 'published') {
        return arr.filter(one => one.published)
      }
    }
  }

  const fetchDigests = async (callback = () => void (0)) => {
    try {
      const response = await fetch(`${API_URL}/admin/api/skynet/digest-history`);
      const data = await response.json();
      setDigestList(data);
    } catch (err) {
      message.error('Failed to fetch digest history');
    }
  };

  useEffect(() => {
    fetchDigests()
  }, []);

  const handleCreateDigest = async () => {
    try {
      const response = await fetch(`${API_URL}/admin/api/skynet/digest-create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ digest }),
      });
      const data = await response.json();

      if (data) {
        await fetchDigests(); // Fetch the updated list of digests after creating
        setEditingId(data[0]._id);
        setSelectedItem(data[0]._id);
        message.success('Digest created successfully');
      } else {
        message.error('Failed to create digest');
      }
    } catch (err) {
      message.error('Failed to create digest');
    }
  };

  const handleUpdateDigest = async () => {
    if (!editingId) return;

    try {
      const response = await fetch(`${API_URL}/admin/api/skynet/digest-update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ _id: editingId, digest }), // Pass digest content
      });
      const data = await response.json();

      setDigestList(digestList.map((d) => (d._id === editingId ? { ...d, digest } : d)));
      setEditingId(null);
      setDigest('');
      setSelectedItem(null);
      message.success('Digest updated successfully');
    } catch (err) {
      message.error('Failed to update digest');
    }
  };

  const handleEditClick = (id, currentDigest) => {
    setEditingId(id);
    setDigest(currentDigest); // Set the digest content when editing
    setSelectedItem(id);
  };

  return (<>
    <AdminMenu active="skynet" />
    <div className="b" style={{ position: 'relative', minHeight: '100vh', padding: '2em 2em 12em 2em', background: '#efefef' }}>
      <div className="_fv _fgap2">
        <h1>🌐 Skynet</h1>

        <Row gutter={16} style={{ minHeight: '100vh', background: '#efefef' }}>
          <Col span={10}>
            <div style={{ position: 'sticky', top: '1em' }}>
              <div className="_fv _fgap2">



                <Button
                  style={{ opacity: editingId ? 1 : 0.2 }}
                  disabled={!editingId}
                  ghost icon="add" onClick={() => handleEditClick('', '')}>
                  Create new message container
                </Button>


                <div className="_fv _rad1" style={{ padding: '1em', background: '#fff' }}>
                  <div className="_fv">
                    <TextArea
                      value={digest}
                      onChange={(e) => setDigest(e.target.value)} // Update the digest state with the TextArea content
                      rows={10}
                      placeholder="Enter your digest message"
                    />
                  </div>
                  <div className="_f _fm">
                    <Button
                      disabled={!digest.length && editingId && digest !== getCurrentDigestText()}
                      type="primary"
                      onClick={editingId && digest.length ? handleUpdateDigest : handleCreateDigest}>
                      {editingId ? 'Update' : 'Save'}
                    </Button>


                    <Button
                      onClick={() => {
                        _previewDigest(editingId, () => {
                          message.success('Preview sent successfully')
                        })
                      }}
                    >Send Preview</Button>


                    <div className="_fr _f100">


                    </div>

                  </div>
                </div>


                <div className="_fv _rad1" style={{ padding: '1em', background: '#fff' }}>

                  <Segmented
                    options={[
                      {
                        label: `Send to all`,
                        value: 'all'
                      }, {
                        label: `Send to specific receients`,
                        value: 'specific'
                      }
                    ]}
                    onChange={(value) => {
                      if (value === 'all') {
                        setRecepients()
                      }
                      if (value === 'specific') {
                        setRecepients([])
                      }
                    }}
                  />

                  {recepients && <div className="_fv">
                    <TextArea
                      value={recepients.toString()}
                      onChange={(e) => setRecepientsArrray(e.target.value)} // Update the digest state with the TextArea content
                      rows={10}
                      placeholder="Paste comma-separated recepient tokens 123,345,567..."
                    />
                  </div>}


                  <div className="_fv">
                    {selectedItem && !selectedItem.published && <div className="_f _f100">
                      <div className="_fv _f100">
                        <div className="_f100">
                              <Switch
                                active={allowed}
                            handle={() => setAllowed(prev => !prev)}
                          />
                          <div>I'm sure</div>
                            </div>
                            <Button
                              disabled={!allowed}
                              red
                              onClick={() => {
                                if (allowed) _publishDigest(editingId, recepients, () => {
                                  message.success('Digest published successfully')
                                })
                              }}
                            >Publish</Button>
                          </div>


                        <div className="_f100 _fr _fm">

                        </div>

                    </div>}
                  </div>


                </div>


              </div>
            </div>
          </Col>
          <Col span={14}>
            <div className="_fv">


              <Segmented
                options={[{
                  label: `All (${filterList(digestList, '').length})`,
                  value: ''
                }, {
                  label: `Drafts (${filterList(digestList, 'drafts').length})`,
                  value: 'drafts'
                }, {
                  label: `Published (${filterList(digestList, 'published').length})`,
                  value: 'published'
                }]}
                onChange={(value) => {
                  setCurrentSet(value)
                }}
              />

              <List
                bordered
                dataSource={filterList(digestList, currentSet)}
                renderItem={(item) => (
                  <List.Item
                    className="_rad1"
                    gutter={16}
                    style={{
                      boxShadow: `0 0 0px 3px ${selectedItem === item._id ? '#0000ff' : 'transparent'}`,
                      borderSize: '2px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                      padding: 0,
                      margin: 16,
                    }}
                    onClick={() => handleEditClick(item._id, item.digest)}
                  >
                    <div className="_fv _f100">
                      <EmailPreview>{item.digest}</EmailPreview>
                      <div className="_fv _pd1">
                        <div className="_f _fm">
                          <div style={{ width: '12em' }}>_id:</div>
                          <Input
                            value={item._id}
                            variant="filled"
                          />
                        </div>
                        <div className="_f _fm">
                          <div style={{ width: '12em' }}>published:</div>
                          <Input value={item.published} variant="filled" />
                        </div>
                        <div className="_f _fm">
                          <div style={{ width: '12em' }}>timestamp:</div>
                          <Input
                            variant="filled"
                            value={`${getMessageDate({ timestamp: item.timestamp })} ${getMessageTime({ timestamp: item.timestamp })}`}
                          />
                        </div>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </>);
};