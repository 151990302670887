import React, { useContext, useEffect, useState } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { IconList } from '../UiKit/IconList';
import { TooltipH } from '../UiKit/TooltipH';

export function ListAvatarsLayout(props) {

  const {
    icon = '',
    // panelExpanded,
    // setPanelExpanded,
    title,
    quantity,
    listId,
    children,
    addComponent,
    addComponentClosed,
    className = '',
    addLeft,
    addRight,
    privateList
  } = { ...props };
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const $ui = context.$ui;

  const [cls, setCls] = useState(className)
  useEffect(() => {
    if (className !== cls) setCls(className)
  }, [className])

  return (
    <>

      <div
        name={listId}
        className={`x_choose_section ${cls ? `x_choose_section--${cls}` : ''}`}
      >
        {Boolean(title) && <div
          className={`x_choose_section_title0 ${cls ? `x_choose_section_title0--${cls}` : ''}`}
        >
          <div
            className={`x_choose_section_header ${cls ? `x_choose_section_header--${cls}` : ''}`}
          >
            <div
              className={`x_choose_section_title ${cls ? `x_choose_section_title--${cls}` : ''}`}
            >

              {Boolean(icon) && <IconList icon={icon} q={quantity} />} {title}

              {Boolean(quantity) && <div className="x_choose_menu_counter">
                {quantity}
              </div>}



              <div className="x_choose_section_header_grad0 r" />
            </div>

            {context.state.peoplePanel && (addLeft || addRight || privateList) && <div className="x_choose_section_header_right">
              <div className="x_choose_section_header_left">
                {addLeft}
              </div>
              {addRight}

              {privateList &&
                <TooltipH
                  title={$ui('people_private_tooltip')} placement='bottom'>
                  <div className="x_choose_menu_label"><div className="t_icon">lock</div>
                    {/* <div className="mobile_v_no">
                      {$ui('people_private_list')}
                    </div> */}
                  </div>
                </TooltipH>
              }

            </div>}


            {context.state.peoplePanel && addComponent}

            {!context.state.peoplePanel && <div className='x_choose_section_city'>{addComponentClosed}</div>}

          </div>

        </div>}

        {/* <div className={`x_choose_section_content ${cls ? `x_choose_section_content--${cls}` : ''}`}> */}
        {children}
        {/* </div> */}


      </div>


    </>
  )
}