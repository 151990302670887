import React, { useContext } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';



const TimeTickMarks = ({ tickInterval }) => {
  const totalMinutes = 240; // Total minutes for 6 tones
  const numberOfTicks = Math.floor(totalMinutes / tickInterval) + 1; // Add 1 to include the last tick at the end

  return (<>


    <div className="x_time_uncertain_time0"
      style={{
        inset: 0,
        fontSize: '4px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRight: '1px solid #00000038',
        height: '0.4em',
        position: 'relative'
      }}
    >
      <div
        style={{
          fontSize: '4px',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          // borderRight: '1px solid #00000038',
          // height: '2em'
        }}
      >
        <div className={`x_time_tick`} />
        <div className={`x_time_tick x_time_tick--10`} />
        <div className={`x_time_tick x_time_tick--10`} />
        <div className={`x_time_tick x_time_tick--10`} />
        <div className={`x_time_tick x_time_tick--10`} />
        <div className={`x_time_tick x_time_tick--10`} />
        {/* <div className={`x_time_tick x_time_tick--20`}
          style={{
            borderRight: '1px solid #0006',
            borderLeft: 'none',
            position: 'absolute',
            right: 0,
            width: '1px',
            height: '4em'
          }}
        /> */}
      </div>


      {Array.from({ length: numberOfTicks }, (_, index) => {
        const isFifthTick = (index + 1) % 5 === 0;
        const isSecondTick = (index + 1) % 2 === 0;

        return (
          <div
            key={index}
            className={`x_time_tick `}
            // className={`x_time_tick ${isFortyTick ? 'x_time_tick--20' : isFifthTick ? 'x_time_tick--10' : isSecondTick ? 'x_time_tick--5' : ''}`}
          />
        );
      })}
    </div>
  </>
  );
};

export const RectificationTime = React.memo(({
  design,
  mode,
  varName,
  getColor,
  getTone,
  getBase
}) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const $vars = context.$vars;

  const sunMovement = varName === 'diet' || varName === 'motivation';

  const TICK_INTERVAL = sunMovement ? 100 / 33.3333333333333 : 100; // Interval in minutes for each tick

  // Safety check: only proceed if design.gates[0].perTone is defined
  if (!design || !design.gates || !design.gates[0] || design.gates[0].perTone === undefined) {
    return null; // or you could return a placeholder component
  }

  const color = getColor(design, null, true);
  const tone = getTone(design, null, true);
  const base = getBase(design, null, true);

  const getMinutes = (toneNumber, percentage) => {
    // Calculate minutes based on the percentage of 40 minutes
    let minutes = Math.round((percentage / 100) * 40);

    // Adjust based on the tone number
    if (toneNumber === 1 || toneNumber === 4) {
      // For tones 1 and 4, show minutes since the start
      return minutes;
    } else if (toneNumber === 3 || toneNumber === 6) {
      // For tones 3 and 6, show minutes until the end
      return 40 - minutes;
    } else {
      // For tones 2 and 5, show minutes relative to the middle
      return Math.abs(20 - minutes);
    }
  };

  const getHoursForNodes = (toneNumber, percentage) => {
    // Calculate hours based on the percentage of 144 hours (6 days)
    const totalHours = 144;
    let hours = Math.round((percentage / 100) * totalHours);

    // Adjust based on the tone number
    if (toneNumber === 1 || toneNumber === 4) {
      // For tones 1 and 4, show hours since the start
      return hours;
    } else if (toneNumber === 3 || toneNumber === 6) {
      // For tones 3 and 6, show hours until the end
      return totalHours - hours;
    } else if (toneNumber === 2 || toneNumber === 5) {
      // For tones 2 and 5, show hours relative to the middle
      return Math.abs(totalHours / 2 - hours);
    } else {
      // Handle unexpected tone numbers
      throw new Error('Invalid tone number');
    }
  };

  // Method 1: Calculate cumulative percentage within 100%
  const calculateCumulativePercentage = (toneNumber, percentage) => {
    if (toneNumber < 1 || toneNumber > 6) {
      throw new Error('Color number must be between 1 and 6');
    }

    let cumulativePercentage = 0;

    for (let i = 1; i < toneNumber; i++) {
      cumulativePercentage += 100 / 6;
    }

    cumulativePercentage += (percentage / 100) * (100 / 6);

    // Round to the nearest integer
    const roundedPercentage = Math.round(cumulativePercentage);

    // console.log('varName = ', varName);
    // console.log('percentage = ', percentage);
    // console.log('toneNumber = ', toneNumber);
    // console.log('cumulativePercentage = ', roundedPercentage);

    return roundedPercentage;
  };

  // Method 2: Calculate percentage within pair
  const calculatePairPercentage = (toneNumber, percentage) => {
    if (toneNumber < 1 || toneNumber > 6) {
      throw new Error('Color number must be between 1 and 6');
    }

    const pairNumber = Math.ceil(toneNumber / 3);
    const positionInPair = (toneNumber - 1) % 3;

    let result;
    if (positionInPair === 0) {
      result = percentage;
    } else if (positionInPair === 1) {
      result = 100 + percentage;
    } else {
      result = 200 + percentage;
    }

    // Round to the nearest integer
    const roundedResult = Math.round(result);

    // console.log('varName = ', varName);
    // console.log('percentage = ', percentage);
    // console.log('toneNumber = ', toneNumber);
    // console.log('pairPercentage = ', roundedResult);

    return roundedResult;
  };

  console.log('color = ', color);

  const DEFAULT_COLOR = '#FBEB6B';
  const MARGINAL_COLOR = '#FB8D6B';
  const THRESHOLD = 30; // 30% of the tone
  const THRESHOLD_NODE = 2; // 30% of the tone

  const getTickerColor = (tone, perTone, isSun) => {
    const treshold = isSun ? THRESHOLD : THRESHOLD_NODE;

    if ((tone === 1 || tone === 4) && perTone < treshold) {
      return MARGINAL_COLOR;
    }

    if ((tone === 3 || tone === 6) && perTone > (100 - treshold)) {
      return MARGINAL_COLOR;
    }

    return DEFAULT_COLOR;
  };

  const tickerColor = getTickerColor(tone.tone, tone.perTone, sunMovement);

  return (
    <div className="x_phs_item_time0">
      <TimeTickMarks tickInterval={TICK_INTERVAL} />
      <div className="x_phs_item_time_tick"
        style={{
          zIndex: 2,
          position: 'relative',
          marginLeft: `${calculateCumulativePercentage(tone.tone, tone.perTone)}%`
        }}
      >
        <div style={{ position: 'relative', marginLeft: -10 }}>
          {sunMovement && <span style={{ marginLeft: '1.8em', position: 'absolute', fontSize: '12px', color: MARGINAL_COLOR, fontWeight: 'bold', width: '6em', paddingTop: '0.2em' }}>
            {tickerColor === MARGINAL_COLOR && <>{getMinutes(tone.tone, tone.perTone)} min.</>}
          </span>}
          {/* {!sunMovement && <span style={{ marginLeft: '1.8em', position: 'absolute', fontSize: '12px', color: MARGINAL_COLOR, fontWeight: 'bold', width: '6em', paddingTop: '0.2em' }}>
            {tickerColor === MARGINAL_COLOR && <>~{getHoursForNodes(tone.tone, tone.perTone)} hrs.</>}
          </span>} */}
          {renderTick(tickerColor)}
        </div>
      </div>
      <div className="_tc _t08">{sunMovement ? $ui('rectification_changes_sun') : $ui('rectification_changes_node')}</div>
    </div>
  )
})

const renderTick = (color) => <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.21114 2.7574L1.44721 16.2853C0.782313 17.6151 1.7493 19.1797 3.23607 19.1797L16.7639 19.1797C18.2507 19.1797 19.2177 17.6151 18.5528 16.2853L11.7889 2.7574C11.0518 1.2833 8.94819 1.2833 8.21114 2.7574Z" fill={color} stroke="transparent" />
</svg>
