import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { Avatar } from './Avatar';
import { AvatarName } from './AvatarName';
import { Icon } from './Icon';
import { TooltipH } from './TooltipH';

export function FriendsWith({
  filterCase = a => a,
  globally,
  title = '',
  titleAfter = '',
  size = 'lg',
  people,
}) {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $center = context.$center;
  const $ui = context.$ui;

  const limit = 10;

  people = people || context.followings;
  people = filterCase(context.followings)

  const percent = ((people.length / context.followings.length) * 100).toFixed(0)

  const navigate = useNavigate();



  return (

    <div className="_fv _fgap2" style={{
      // marginBottom: '-1em',
      // width: size === 'lg' ? 'auto' : '100%',
      width: '100%',
      overflow: size === 'lg' ? 'inherit' : 'hidden',
      // marginLeft: -8, marginRight: -8, marginBottom: -8,
      // paddingRight: 0,
      fontSize: size === 'lg' ? '1em' : '0.9em',
    }}>
      <div className="_fv _fgap08" >

        <div className="_fm _fgrow">
          <div className="_f _fgap04 _tnowrap">
            {title} {!globally && <>
              <div className="_fm _fgap04"><Icon>group</Icon></div> <b>{percent}%</b>
              <b>{$ui('edu_gate_activated_in_friends')}</b></>} {titleAfter}
          </div>


          {Boolean(globally) && <div className="_f100 _fr" style={{ paddingRight: '1em' }}>
            <TooltipH title={$ui('friends_with_this_tooltip')}>
              <div className="_fm _fgap04"><Icon>group</Icon> <b>{percent}%</b></div>
            </TooltipH>

            <TooltipH title={$ui('global_with_this_tooltip')}>
              <div className="_fm _fgap04 _o04"><b>{globally}</b><Icon>public</Icon></div>
            </TooltipH>
          </div>}
      </div>

        <div className={`_f  ${size === 'lg' ? '_fgap02 _fwrap _f100' : '_fgap0  _f100'}`} style={{
          overflowX: size === 'lg' ? 'inherit' : 'auto',
          overflowY: size === 'lg' ? 'auto' : 'inherit',
          // minHeight: '4em',
        }}>
        {people
          .map(one => {
            return (
              <TooltipH title={<AvatarName avatar={one.avatar} />}
                key={one.avatar.token}
              >
                <div className="_fv _fm _fgap04 _click _fnogrow"
                key={`${title}_${one.user.token}`}
                onClick={() => navigate(`/u/${one.user.token}`)}
              >
                <Avatar avatar={one.avatar} style={{ fontSize: size === 'lg' ? '1.2em' : '1em' }} />
                  <div style={{
                    width: people.length > 3 ? '4.6em' : '7em',
                    height: '2em',
                    fontSize: '0.8em',
                    fontWeight: '500',
                    marginRight: '-0.4em'
                  }} className="ellipsis">
                  <AvatarName avatar={one.avatar} />
                </div>
              </div>
              </TooltipH>

            )
          })}
      </div>



      </div>
    </div>)
}