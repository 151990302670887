import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  padding: 0.5em 1em;
  border: 1px solid #ccc;
  border-radius: 0.5em;
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: 1.4em;
  font-weight: 600;
  &._active {
    background-color: #1900ff;
    color: #fff;
    border-color: #1900ff;
    font-weight: bold;
  }
`;

export function AdminMenu({ active = 'users' }) {
  return (
    <div className='_f _fgap2' style={{ padding: '1em 1em' }}>
      <h1>🔧 Admin</h1>
      <StyledLink to="/admin/" className={active === 'users' ? '_active' : ''}  >Users</StyledLink>
      <StyledLink to="/admin/skynet" className={active === 'skynet' ? '_active' : ''}>Skynet</StyledLink>
      <StyledLink to="/admin/texts" className={active === 'texts' ? '_active' : ''} >Texts</StyledLink>
    </div>
  )
}